import { CopilotRoadmapPlanStatusType } from '@crimson-education/common-config';
import { Button, Dropdown, MenuProps, notification, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { StyledButtonFilled } from 'src/components/CrButton';
import { EntryPointCheckType } from 'src/types/global';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import UpdateRoadmapMissionModal from 'src/modals/CopilotRoadmapModal';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { GET_SESSION_MISSION_RECOMMENDATIONS, MissionUpdateRecommendation } from 'src/graphql/Copilot';
import { useSkipSession } from 'src/Hooks/useSkipSession';
import { CHECK_ENTRY_POINT_FOR_ROADMAP, CheckEntryPointData } from '@crimson-education/core-shared-graphql';
import './style.css';

const PlanToolTip = (): JSX.Element => {
  return (
    <Tooltip
      title={
        <>
          <div style={{ fontWeight: 700, fontSize: '14px' }}>How does this function work?</div>
          <div style={{ fontWeight: 500, fontSize: '14px', marginTop: '8px' }}>
            This function guides you through the procedure for generating the Roadmap file, an initial strategy document
            designed for easy sharing with students and parents. Created by Copilot AI, it integrates notes from the
            ISM/RMM. Additionally, it incorporates missions added in the Student Center and Roadmap to deliver a
            comprehensive planning guide.
          </div>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};

const RoadmapPdfButtonComponent = ({ entryPoints }: { entryPoints: EntryPointCheckType }): JSX.Element => {
  const { studentCenterApiClient, crimsonAppApiClient } = useContext(ApolloClientContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectSessionId, setSelectSessionId] = useState(0);
  const [selectRegion, setSelectRegion] = useState('');
  const studentId = window?.xprops?.studentId;
  const divisions = entryPoints.divisions;
  const planInitStatus = entryPoints.planInitStatus;
  const [skipSession] = useSkipSession();

  const checkMissionVisible = async (sessionId: number) => {
    const result = await studentCenterApiClient.query<{
      missionRecommendationsBySession: Array<MissionUpdateRecommendation>;
    }>({
      query: GET_SESSION_MISSION_RECOMMENDATIONS,
      variables: {
        sessionId,
        onlyQuery: true,
      },
      fetchPolicy: 'network-only',
    });
    let visible = false;

    const recommendations = result?.data?.missionRecommendationsBySession ?? [];
    if (recommendations.length > 0) {
      if (recommendations.every((it: MissionUpdateRecommendation) => it.status === 'INIT' || it.status === 'ACTIVE')) {
        visible = true;
      }
    }
    return visible;
  };

  const checkEntryPoint = async () => {
    const result = await crimsonAppApiClient.query<{
      checkEntrypointForRoadmap: CheckEntryPointData;
    }>({
      query: CHECK_ENTRY_POINT_FOR_ROADMAP,
      variables: {
        userId: studentId,
      },
      fetchPolicy: 'network-only',
    });

    const entryPoint = result?.data?.checkEntrypointForRoadmap;
    return entryPoint;
  };
  const skipGenerateMission = async () => {
    await skipSession({
      variables: {
        input: {
          sessionId: selectSessionId,
        },
      },
    }).then(() => {
      const status = selectRegion === 'UK' ? planInitStatus.ukStatus : planInitStatus.usStatus;
      initGeneratePlan(status, selectRegion);
      setOpenModal(false);
    });
  };

  const initGeneratePlan = async (status: string | null, region: string, roadmapId?: string | null) => {
    switch (status) {
      case CopilotRoadmapPlanStatusType.FirstStageCompleted:
      case null:
      case CopilotRoadmapPlanStatusType.FirstStageInProgress:
      case CopilotRoadmapPlanStatusType.FirstStageFailed: {
        window.xprops?.generateRoadmapPlan(true, region);
        break;
      }
      case CopilotRoadmapPlanStatusType.SecondStageInProgress:
      case CopilotRoadmapPlanStatusType.SecondStageInFailed:
      case CopilotRoadmapPlanStatusType.InProgress:
      case CopilotRoadmapPlanStatusType.Done: {
        roadmapId && window?.xprops?.history.push('/copilot/roadmap/' + roadmapId);
        break;
      }
      default: {
        notification.warn({
          message: 'Unexpected status: ' + status,
          description: 'Please try again later',
        });
        break;
      }
    }
  };

  const genRoadmapForRegion = async (region: string) => {
    const domain = window?.xprops?.domain || 'http://localhost:3000';
    setSelectRegion(region);

    const entryPoints = await checkEntryPoint();
    const status = region === 'UK' ? entryPoints.planInitStatus.ukStatus : entryPoints.planInitStatus.usStatus;
    const meeting = region === 'UK' ? entryPoints.meetingStatus?.UK : entryPoints.meetingStatus?.US;
    const roadmapId = region === 'UK' ? entryPoints.planInitStatus.ukId : entryPoints.planInitStatus.usId;
    if (!meeting) {
      notification.error({
        duration: 0,
        message: 'ISM/RMM Completion Required',
        icon: <IconWrapper color={'#ED4B53'} />,
        className: 'notification',
        description: (
          <>
            <div style={{ marginBottom: '12px' }}>
              The Roadmap Report requires an ISM or RMM session to be completed with the student. Please finish at least
              one such session, or update the session type of an existing session to ISM or RMM, and then restart the
              process.
            </div>
            <a
              target="__blank"
              href={`${domain}/users/${studentId}/otherSessions`}
              style={{ textDecoration: 'underline' }}
            >
              Go to Session History
            </a>
          </>
        ),
      });
      return;
    }
    const sessionId = region === 'UK' ? entryPoints.sessionIds?.UK : entryPoints.sessionIds?.US;
    if (sessionId && !status) {
      const missionVisible = await checkMissionVisible(sessionId);
      if (missionVisible) {
        setSelectSessionId(sessionId);
        setOpenModal(true);
      } else {
        !status && window.xprops?.showInitRoadmapLoading();
        initGeneratePlan(status, region, roadmapId);
      }
    } else {
      !status && window.xprops?.showInitRoadmapLoading();
      initGeneratePlan(status, region, roadmapId);
    }
  };

  const items: MenuProps['items'] = [];
  const createMenuItem = (region: 'US' | 'UK', status: string | null) => ({
    key: region,
    label: (
      <EditButton onClick={() => genRoadmapForRegion(region)}>
        {status ? 'Edit' : 'Generate'} {region} Roadmap Report {region === 'US' ? '🇺🇸' : '🇬🇧'}
      </EditButton>
    ),
  });
  items.push(createMenuItem('US', planInitStatus.usStatus));
  items.push(createMenuItem('UK', planInitStatus.ukStatus));
  const region = divisions.includes('US') ? 'US' : 'UK';
  return (
    <>
      {divisions.length === 0 || (divisions.includes('US') && divisions.includes('UK')) ? (
        <Dropdown menu={{ items }} placement="bottom">
          <RoadmapPdfButton>
            Generate Roadmap Report <PlanToolTip />
          </RoadmapPdfButton>
        </Dropdown>
      ) : (
        <RoadmapPdfButton onClick={() => genRoadmapForRegion(region)}>
          {planInitStatus.usStatus || planInitStatus.ukStatus ? 'Edit ' : 'Generate'} Roadmap Report <PlanToolTip />
        </RoadmapPdfButton>
      )}
      <UpdateRoadmapMissionModal
        onClose={() => setOpenModal(false)}
        onSkip={skipGenerateMission}
        open={openModal}
        sessionId={selectSessionId}
      />
    </>
  );
};

const RoadmapPdfButton = styled(StyledButtonFilled)`
  font-size: 14px;
  height: 40px;
  &:hover {
    color: white;
    border: var(--border-nocolor) rgb(64, 68, 184);
    background-color: #2d30b4;
    box-shadow: 0px 6px 10px -1px rgba(29, 30, 43, 0.07);
  }
`;

const EditButton = styled(Button)`
  font-size: 12px;
  border: none;
  color: #73747d;
  font-weight: 500;
  background: transparent;
  box-shadow: none;
  &:hover {
    background: transparent;
  }
`;

const IconWrapper = styled.div<{ color: string }>`
  width: 3px;
  height: 24px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

export default RoadmapPdfButtonComponent;
